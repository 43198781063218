.summary-title {
  display: flex;
  padding-left: 12px;
}
.summary-title .summary-title-item {
  margin-right: 20px;
}
.zdcz {
  color: #409EFF;
  font-size: 12px;
  margin-bottom: 10px;
  cursor: pointer;
}
 /deep/ .gl {
  background: #ffc107;
}
